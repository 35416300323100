import React, { Component } from "react";
import "../style/share.scss";
import shareBox from "../image/share-bg.png";
import shareTop from "../image/share-top.jpg";
import share1 from "../image/share1.png";
import share2 from "../image/share2.png";
import share3 from "../image/share3.png";
import share4 from "../image/share4.png";
import share5 from "../image/share5.png";
import share6 from "../image/share6.png";
import share7 from "../image/share7.png";
import backImg from "../image/backImg.png";
import draw_success from "../image/draw-success.png";
import draw_error from "../image/draw-error.png";
import Loading from "./common/Loading";
import * as GetStoryListAPI from "../apis/GetStoryListAPI";
import * as GetCommentListAPI from "../apis/GetCommentListAPI";
import moment from "moment";

import Rating from "@material-ui/lab/Rating";
import ZanIcon from "../icon/ZanIcon";
import CommentIcon from "../icon/CommentIcon";
import ReprintIcon from "../icon/ReprintIcon";
import muted from "../image/muted.mp3";
import ck3 from "../image/ck3.mp3";
import wxSend from "../image/wxSend.mp3";
import wxtip from "../image/wxtip.mp3";
import winner from "../image/win.mp3";
import { throttle } from "lodash";
import spinner from "../image/spinner.mp3";
import step from "../image/step.mp3";

import Call from "../icon/Call";
import Message from "../icon/Message";
import Telegram from "../icon/Telegram";
import Twtter from "../icon/Twtter";
import Facebook from "../icon/Facebook";
import "../style/fail.scss";
import MobileDetectUtil from "../utils/MobileDetectUtil";
// import Line from "../icon/Line";
import WhatsApp from "../image/WhatsApp.png";
import shareBg from "../image/share_bg.png";
import Line from "../image/line-jp.png";
import CloseIcon from "../icon/CloseIcon";
import * as CountPixelEventAPI from "../apis/CountPixelEventAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";

const invitation_text =
  "無料で入手Redmi BudsクレームイユーＳ 15g，限定5000枚、すぐに！";
const HTTP_REQUEST_URL =
  window.location.protocol + "//" + window.location.host + "?ad=share";

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareList: [
        {
          img: share1,
          img2: backImg,
          title: "Uniblade Trimmer ",
          active: false,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share2,
          img2: backImg,
          title: "Power Bank",
          active: false,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share3,
          img2: backImg,
          title: "Thanks",
          active: false,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share4,
          img2: backImg,
          title: "Redmi Buds",
          active: false,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share5,
          img2: backImg,
          title: "Xiaomi Pad",
          active: false,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share3,
          img2: backImg,
          title: "Thanks",
          active: false,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share6,
          img2: backImg,
          title: "Redmi Watch 3",
          active: false,
          amount: 0,
          back: false,
          num: 1,
          amount: 0,
        },
        {
          img: share7,
          img2: backImg,
          title: "Xiaomi 14 Ultra",
          active: false,
          amount: 0,
          back: false,
          num: 1,
          amount: 0,
        },
      ],
      currentActiveIndex: 0,
      drawMask: false,
      shareMask: false,
      drawProduct: null,
      win: false,
      story: {},
      commentList: [],
      storyTime: "",
      commentList: [],
      loadingShow: false,
      audioEnabled: localStorage.getItem("audioEnabled") === "true",
      drawCount: JSON.parse(localStorage.getItem("drawCount")) || 5,
      share: [
        {
          title: "Line",
          url: `https://line.me/R/share?text=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "WhatsApp",
          url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${invitation_text} ${HTTP_REQUEST_URL}`
          )}&url=${encodeURIComponent(HTTP_REQUEST_URL)}`,
          isClick: false,
        },
        {
          title: "Sms",
          url: `sms:${
            MobileDetectUtil.judgeClient() === "iOS" ? "&" : "?"
          }body=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Telegram",
          url: `https://telegram.me/share/url?text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Twitter",
          url: `https://twitter.com/intent/tweet?lang=en&text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Facebook",
          url: `https://www.facebook.com/dialog/share?app_id=407682420960&display=popup&href=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
      ],
      shareClick: JSON.parse(localStorage.getItem("share")) || null,
      tipMask: false,
    };
    this.clickToDrawing = this.clickToDrawing.bind(this);
    this.clickToRedrawing = this.clickToRedrawing.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.routeToContact = this.routeToContact.bind(this);
    this.playNotificationSound = this.playNotificationSound.bind(this);
    this.changeBack = this.changeBack.bind(this);
    this.closeTipMask = this.closeTipMask.bind(this);

    this.notificationSound = new Audio();
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    // 检查 localStorage 中是否有 audioEnabled 状态
    if (!this.state.audioEnabled) {
      window.addEventListener("click", this.handleUserInteraction, {
        once: true,
      });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // 试驾数
    let driveNum = Math.floor(Math.random() * (50 - 200 + 1)) + 200;
    // 获取当前时间戳
    let now = new Date().getTime();
    // 获取前后n天时间
    let endTime = new Date(now - 10 * 24 * 60 * 60 * 1000).getTime();
    let startTime = new Date(now - 13 * 24 * 60 * 60 * 1000).getTime();
    let oneDayTime = new Date(now - 1 * 24 * 60 * 60 * 1000).getTime();
    // 随机帖子时间
    let renderTime =
      Math.floor(Math.random() * (endTime - startTime + 1)) + startTime;
    // 格式化
    let storyTime = moment(renderTime).format("MMMM D . h:mm A");
    this.setState({ storyTime, driveNum });
    let time = [renderTime, now];
    this.toggleShow();
    GetStoryListAPI.getStoryList(1, 4).then((res) => {
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        let id = data.list[0].id;
        this.setState({ story: data.list[0] }, () => {
          GetCommentListAPI.getCommentListAPI(1, 30, id).then((res) => {
            this.toggleShow();
            var code = res.data.code;
            if (code === 1) {
              var data = res.data.data.list;
              data.map((item, index) => {
                item.loveState = false;
                // 点赞数
                item.num = Math.floor(Math.random() * (1 - 30 + 1)) + 30;
                let render =
                  Math.floor(Math.random() * (time[1] - time[0] + 1)) + time[0];
                if (index < 3) {
                  render =
                    Math.floor(Math.random() * (time[1] - oneDayTime + 1)) +
                    oneDayTime;
                  if (render < time[1]) {
                    time[1] = render;
                    item.time = moment(render).fromNow();
                  }
                } else {
                  if (render < time[1]) {
                    time[1] = render;
                    item.time = moment(render).format("MMM D . h:mm A");
                  }
                }
                // 评分数
                item.star = Math.floor(Math.random() * (5 - 4 + 1)) + 4;
              });
              this.setState({ commentList: data });
            }
          });
        });
      }
    });
    window.addEventListener("scroll", this.scroll, true);
  }

  clickToDrawing() {
    console.log("点击！！！！！！");
    this.changeBack();
    let { shareList, currentActiveIndex, drawCount } = this.state;
    setTimeout(() => {
      this.playNotificationSound(muted);
      let intervalTime = 300; // 初始间隔时间
      const maxIntervals = 40; // 最大循环次数
      const accelerateIntervals = 15; // 加速阶段的循环次数
      const steadyIntervals = 20; // 匀速阶段的循环次数
      let currentInterval = Math.floor(Math.random() * (8 - 0 + 1)) + 0;
      var ramdon = [4, 7];
      var index = Math.floor(Math.random() * ramdon.length);
      // 随机选择中奖的索引（从1到length-1），即不会中奖的索引
      let randomWinningIndex = ramdon[index];
      // let randomWinningIndex = 4;

      const animate = () => {
        if (shareList[currentActiveIndex]) {
          shareList[currentActiveIndex].active = false;
        }
        currentActiveIndex = (currentActiveIndex + 1) % shareList.length;
        shareList[currentActiveIndex].active = true;

        // 播放步骤音效并确保每次从头开始播放
        this.playNotificationSound(step); // 每一步都播放音效
        this.setState({ shareList, currentActiveIndex });

        currentInterval++;

        // 加速阶段
        if (currentInterval <= accelerateIntervals) {
          intervalTime *= 0.85; // 模拟加速
        }
        // 匀速阶段
        else if (
          currentInterval > accelerateIntervals &&
          currentInterval <= accelerateIntervals + steadyIntervals
        ) {
          intervalTime = 150; // 匀速阶段保持间隔时间
        }
        // 减速阶段
        else {
          intervalTime *= 1.2 + Math.random() * 0.2; // 模拟减速
        }

        if (currentInterval < maxIntervals) {
          setTimeout(animate, intervalTime); // 递归调用，动态调整时间
        } else {
          // 确定最终中奖的奖品
          let finalItem;
          if (drawCount > 1) {
            // 前几次不中，随机选择一个非中奖的索引
            finalItem = shareList[randomWinningIndex];
          } else if (drawCount === 1) {
            // 最后一次抽奖随机选中中奖的索引
            finalItem = shareList[randomWinningIndex];
          }

          console.log(
            "动画结束，当前选中的项为：",
            finalItem,
            "index:",
            currentActiveIndex
          );

          // 判断是否中奖
          const win = drawCount === 1; // 第五次中奖

          this.setState(
            {
              drawMask: true,
              drawProduct: finalItem,
              win,
              // drawCount: drawCount - 1 >= 1 ? drawCount - 1 : drawCount, // 更新抽奖次数
            },
            () => {
              // localStorage.setItem(
              //   "drawCount",
              //   JSON.stringify(drawCount - 1 >= 1 ? drawCount - 1 : drawCount)
              // );
              localStorage.setItem("orderDetail", JSON.stringify(finalItem));
            }
          );
        }
      };

      animate(); // 开始动画
    }, 500);
  }

  clivkToShare(index) {
    let ad = JSON.parse(localStorage.getItem("ad"));
    let { share, shareClick, shareList } = this.state;
    if (shareClick === 2) {
      window.open(share[index].url, "_blank");
      setTimeout(() => {
        this.setState({ tipMask: true }, () => {
          shareClick = parseInt(shareClick) + 1;
          this.setState({ shareClick: parseInt(shareClick) });
        });
      }, 2000);
      localStorage.setItem("share", JSON.stringify(shareClick));
      return;
    }
    if (shareClick === 4) {
      window.open(share[index].url, "_blank");
      setTimeout(() => {
        this.setState({ tipMask: true }, () => {
          shareClick = parseInt(shareClick) + 1;
          this.setState({ shareClick: parseInt(shareClick) });
        });
      }, 1000);
      localStorage.setItem("share", JSON.stringify(shareClick));
      return;
    }
    window.open(share[index].url, "_blank");
    // this.updateDrawCount();
    console.log(shareClick);
    if (shareClick === null) {
      shareClick = 1;
    } else {
      shareClick = parseInt(shareClick) + 1;
    }
    localStorage.setItem("share", JSON.stringify(shareClick));
    CountPixelEventAPI.countPixelEvent(
      BASE_URL.replace("https://", ""),
      ad,
      `MIShare-${shareClick}`
    );

    setTimeout(() => {
      shareList.map((item) => (item.active = false));
      this.setState(
        { shareClick: parseInt(shareClick), shareMask: false, shareList },
        () => {
          this.updateDrawCount();
          if (this.state.shareClick >= 7) {
            window.open(share[index].url, "_blank");
            setTimeout(() => {
              this.setState({ show: true });
            }, 2000);
          }
        }
      );
    }, 1000);
  }

  updateDrawCount() {
    let { drawCount } = this.state;
    this.setState(
      {
        drawCount: drawCount - 1 >= 1 ? drawCount - 1 : drawCount, // 更新抽奖次数
      },
      () => {
        localStorage.setItem(
          "drawCount",
          JSON.stringify(drawCount - 1 >= 1 ? drawCount - 1 : drawCount)
        );
      }
    );
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  changeBack() {
    let { shareList } = this.state;
    shareList.map((item) => {
      item.back = true;
    });
    this.setState({ shareList });
  }

  clickToRedrawing() {
    this.setState({ shareMask: !this.state.shareMask, drawMask: false });
  }

  routeToContact() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Customer",
        search: location.search,
      });
    localStorage.setItem("isDraw", "true");
  }

  playNotificationSound = (audioSrc) => {
    fetch(audioSrc)
      .then((response) => response.arrayBuffer())
      .then((data) => this.audioContext.decodeAudioData(data))
      .then((buffer) => {
        const source = this.audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(this.audioContext.destination);
        source.start(0);
      })
      .catch((error) => {
        console.error("Audio playback failed:", error);
      });
  };

  handleUserInteraction = () => {
    // 创建一个用于激活音频播放权限的静音音频
    const silentAudio = new Audio(muted);
    silentAudio
      .play()
      .then(() => {
        console.log("Silent audio played");
        this.setState({ audioEnabled: true }, () => {
          localStorage.setItem("audioEnabled", "true");
        });
      })
      .catch((error) => {
        console.error("Silent audio playback failed:", error);
      });
  };

  closeTipMask() {
    this.setState({ tipMask: false });
  }

  render() {
    const {
      shareList,
      drawMask,
      win,
      drawProduct,
      commentList,
      shareMask,
      shareClick,
      tipMask,
      drawCount,
    } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className={`share-tip-mask ${tipMask ? "share-tip-show" : ""}`}>
          <div className="tip-content">
            <CloseIcon
              size={40}
              color="#fff"
              className="closeIcon"
              onClick={this.closeTipMask}
            />
            {parseInt(shareClick) === 3 &&
              "別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。"}
            {parseInt(shareClick) === 5 && "グループチャットも共有できる"}
          </div>
        </div>
        <div
          className={`shareBox-mask ${shareMask ? "shareBox-mask-show" : ""}`}
        >
          <div className="shareBox-contain">
            <div className="share-bg">
              <img src={shareBg} alt="" />
            </div>
            <div className="title">現在、あなたは運がない！</div>
            <div className="tip">
              招待した友達全員に賞金獲得のチャンスがあり、100％当たる！
            </div>
            <div className="icon-row">
              <div className="li-icon" onClick={() => this.clivkToShare(0)}>
                <img src={Line} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`draw-mask ${drawMask ? "draw-mask-show" : ""}`}>
          <div className="draw-tip-box">
            <img src={win ? draw_success : draw_error} alt="" />
            <div className="tip-box">
              <div className={`tip-txt ${win ? "win-txt" : ""}`}>
                {win ? (
                  <p>
                    おめでとうございます! <br />
                    おめでとうございます{drawProduct && drawProduct.title}
                  </p>
                ) : (
                  <p>
                    あなたの粘り強さは報われる！
                    <br /> もう一度やってみよう。
                  </p>
                )}
              </div>
              <div className="pro-img">
                <img
                  src={win ? drawProduct && drawProduct.img : share3}
                  alt=""
                />
              </div>
              {win ? (
                <div className="tip-btn" onClick={this.routeToContact}>
                  今すぐ請求する
                </div>
              ) : (
                <div className="tip-btn" onClick={this.clickToRedrawing}>
                  再挑戦
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="share-page">
          <div className="bgImage">
            <img src={shareTop} alt="" />
          </div>
          <div className="share-box">
            <img src={shareBox} alt="" />
            <div className="share-box-contain">
              <div className="click-btn" onClick={this.clickToDrawing}>
                <div className="up">開始抽奖</div>
                <div className="down">残り1回</div>
              </div>
              {shareList.map((item, index) => {
                return (
                  <div
                    className={`share-col ${item.active ? "share-active" : ""}`}
                    key={index}
                  >
                    <div className={`img-box ${item.back ? "back-img" : ""}`}>
                      <img src={item.back ? item.img2 : item.img} alt="" />
                    </div>
                    {item.back ? "" : <div className="p">{item.title}</div>}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="share-title-top">{drawCount}回の抽選で100％当選</div>
          <div className="comment-box">
            {commentList.map((item, index) => {
              return (
                <div className="comment-li" key={index}>
                  <div className="user-info">
                    <div className="left">
                      <div className="img">
                        <img src={item.logo} alt="" />
                      </div>
                      <div className="info-txt">
                        <div className="name">{item.nickname}</div>
                        <div className="time">{item.time}</div>
                      </div>
                    </div>
                    <div className="star">
                      <Rating
                        name="read-only"
                        size="small"
                        value={item.star || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="comment-content">
                    <p
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></p>
                  </div>
                  <div className="modelfooter">
                    <div className="phone-name">
                      Xiaomi 14 Ultra White 16 GB + 512 GB
                    </div>
                    <div className="operate">
                      <div className="operate-li">
                        <ZanIcon size={16} />
                      </div>
                      <div className="operate-li">{item.num}</div>
                      <div className="operate-li">
                        <CommentIcon size={16} color="#b0b0b0" />
                      </div>
                      <div className="operate-li">
                        <ReprintIcon size={14} color="#b0b0b0" />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="footer-load">
            <div className="animate-load"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Share;
