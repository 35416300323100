import React from "react";

const LoveIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="5172"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 212.584c88.84-92.952 266.524-92.952 355.368-16.58s88.844 229.116 0 381.864c-57.608 106.116-199.072 212.232-325.528 288a58.316 58.316 0 0 1-59.684 0c-126.456-75.768-267.92-181.884-325.524-288-88.844-152.748-88.844-305.492 0-381.864 88.84-76.372 266.524-76.372 355.368 16.58z"
      fill="#FF4B6A"
      p-id="5173"
    ></path>
    <path
      d="M294.02 231.816c19.712 15.076 20.472 47.248 1.696 71.796-18.776 24.552-50.024 32.244-69.736 17.168-19.712-15.076-20.472-47.248-1.696-71.796 18.776-24.552 50.02-32.244 69.736-17.168z"
      fill="#FFFFFF"
      fillOpacity=".2"
      p-id="5174"
    ></path>
    <path
      d="M855.472 186.608a185.496 185.496 0 0 1 11.896 9.396c88.844 76.372 88.844 229.116 0 381.864-57.608 106.116-199.072 212.232-325.528 288a58.316 58.316 0 0 1-59.684 0c-92.188-55.236-192.352-126.6-262.188-202.34 65.276 60.656 146.424 116.948 222.188 162.34a58.316 58.316 0 0 0 59.684 0c126.456-75.768 267.92-181.884 325.528-288 78.2-134.448 87.568-268.892 28.104-351.26z"
      fillOpacity=".1"
      p-id="5175"
    ></path>
  </svg>
);

export default LoveIcon;
