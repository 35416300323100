import React, { Component } from "react";
import "../../style/common/popup.scss";
import Check from "../../icon/Check";
import CheckNone from "../../icon/CheckNone";
class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popState: false,
      popMsg: false,
      iconType: "",
    };
  }

  alertModalTimeoutInterval = null;

  componentDidMount() {
    window.addEventListener("showAlert", (event) => {
      console.log("~~~~~~~~~~~~~~~~~");
      console.log(event);
      this.setState({
        popState: true,
        popMsg: event && event.detail.text,
        iconType: event && event.detail.iconType,
      });
      if (this.alertModalTimeoutInterval) {
        window.clearTimeout(this.alertModalTimeoutInterval);
      }
      this.alertModalTimeoutInterval = window.setTimeout(() => {
        this.setState({ popState: false, popMsg: "", iconType: "success" });
      }, 2000);
    });
  }

  componentWillUnmount() {
    if (this.alertModalTimeoutInterval) {
      window.clearTimeout(this.alertModalTimeoutInterval);
    }
  }

  render() {
    const { popMsg, popState, iconType } = this.state;
    return (
      <div
        className={`pop-box ${popState ? "pop-show" : ""} ${
          iconType !== "error" ? "" : "error"
        }`}
      >
        <h4>
          {iconType !== "error" ? <Check size={20} /> : <CheckNone size={20} />}

          <p>{popMsg}</p>
        </h4>
      </div>
    );
  }
}

export default Popup;
