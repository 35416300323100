import React, { Component, useState, useEffect } from "react";
import HeadBar from "./common/HeadBar";
import "../style/order.scss";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import { Radio } from "antd";
import Reload from "./common/Reload";
import * as SubmitPayAPI from "../apis/SubmitPayAPI";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "./common/Popup";
import Cookie from "react-cookies";
import * as GetPixelAPI from "../apis/GetPixelAPI";
import * as FacebookAPI from "../apis/FacebookAPI";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reloadingShow: false,
      orderDetail: {},
      PlanList: [
        {
          title: "紙契約の宅配料金(申込不合格の場合もに返金する)",
          checked: true,
          value: "contact_phone",
        },
      ],
      contactType: "contact_phone",
      // test_drive_package: 1,
      reloadingShow: false,
      payment_intent_id: null,
      clientSecret: "",
      stripePromise: "",
      userDetails: {},
      count: 3,
      url: "",
      amount: "",
    };
    this.changeChecked = this.changeChecked.bind(this);
    this.Upload = this.Upload.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
    this.removeAllVideos = this.removeAllVideos.bind(this);
    this.createVideo = this.createVideo.bind(this);
    this.routePlan = this.routePlan.bind(this);
    this.routePage = this.routePage.bind(this);

    this.receiveVideoRef = React.createRef();

    this.clickToPay = this.clickToPay.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    this.setState({ orderDetail }, () => {
      if (orderDetail.userSelfPictureOrVideo) {
        this.removeAllVideos();
        this.createVideo(orderDetail.userSelfPictureOrVideo);
      }
    });
    let { PlanList, contactType } = this.state;
    if (orderDetail) {
      if (orderDetail.contactType) {
        PlanList.map((item, index) => {
          item.checked = false;
          if (item.value === orderDetail.contactType) {
            item.checked = true;
          }
        });
        this.setState({
          PlanList,
          contactType: orderDetail.contactType,
        });
      }
      if (orderDetail.payment_intent_id) {
        this.setState({ payment_intent_id: orderDetail.payment_intent_id });
      }
      orderDetail.contactType = contactType;
      localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    }
    if (Cookie.load("prohibitd_purchase")) {
      console.log("禁止购买------");
      this.setState({ count: 0 });
    }
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    GetPixelAPI.getPixel().then((res) => {
      var data = res.data.data;
      if (data) {
        this.setState({ amount: data.amount });
      }
    });
  }

  changeChecked(index) {
    let { PlanList } = this.state;
    PlanList.map((item, itemIndex) => {
      item.checked = false;
      if (index === itemIndex) {
        item.checked = true;
        this.setState({ contactType: item.value }, () => {
          let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
          orderDetail.contactType = item.value;
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        });
      }
    });
    this.setState({ PlanList });
  }

  Upload(type) {
    let { history, location } = this.props;
    const search = location.search;
    switch (type) {
      case "model":
        history &&
          history.push({
            pathname: "/Model",
            search: location.search,
          });
        break;
      case "customer":
        history &&
          history.push({
            pathname: "/Customer",
            search: location.search,
          });
        break;
      case "card":
        history &&
          history.push({
            pathname: "/Info",
            search: location.search,
          });
        break;
    }
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  // 移除节点下的所有视频元素
  removeAllVideos() {
    const videoElements = document.querySelectorAll("video");
    videoElements.forEach((video) => {
      video.remove();
    });
  }

  //创建新的元素
  createVideo(src) {
    const videoElement = document.createElement("video");
    videoElement.src = BASE_URL + src;
    // videoElement.autoplay = true;
    videoElement.controls = true;
    videoElement.type = "video/mp4";
    videoElement.playsInline = true;
    this.receiveVideoRef.current = videoElement;
    document.querySelector(".video-box").appendChild(videoElement);
  }

  routePlan() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/Plan",
        search: location.search,
      });
  }

  routePage() {
    let { history, location } = this.props;
    const search = location.search;
    history &&
      history.push({
        pathname: "/BMW",
        search: location.search,
      });
  }

  clickToPay() {
    let { payment_intent_id, count } = this.state;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    // orderDetail.test_drive_package = test_drive_package;
    // localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    console.log(count);
    if (count === 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: { text: "本日の購入時間が到着しました!" },
        })
      );
      // 24小时内只允许点三次
      let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      let value = "Prohibitd purchase";
      Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
      return;
    }
    count--;
    console.log(count);
    this.setState({ count });
    let ad = "";
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "";
      console.log(ad);
    }
    orderDetail.ad = ad;
    orderDetail.success_url = "/Success";
    orderDetail.cancel_url = "/Fail";
    orderDetail.payment_intent_id = payment_intent_id;
    orderDetail.type = 11;
    orderDetail.curcrency = 2;
    this.toggleReload();
    SubmitPayAPI.submit(orderDetail)
      .then((res) => {
        console.log(res);
        this.toggleReload();
        var data = res.data.data.data;
        console.log(data);
        if (data !== null) {
          let stripePromise = loadStripe(data.api_publishable_key);
          // 自动滑到底部
          setTimeout(() => {
            window.scrollTo(0, document.documentElement.scrollHeight);
          }, 2000);
          let userDetails = {
            name: orderDetail.firstName,
            email: orderDetail.email,
            address: {
              // city: orderDetail.city,
              state: orderDetail.state,
              country: orderDetail.country,
              postal_code: orderDetail.zipCode,
              line1: orderDetail.address,
            },
          };
          this.setState({
            stripePromise,
            payment_intent_id: data.payment_intent_id,
            clientSecret: data.clientSecret,
            userDetails,
            url: `${BASE_URL}/success?payment_intent_id=${data.payment_intent_id}&subscriptionId=${data.subscriptionId}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
            // url: `http://localhost:3001/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
          });

          orderDetail.payment_intent_id = data.payment_intent_id;
          localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
        } else {
          this.clickToPay();
        }
      })
      .catch((err) => {
        this.toggleReload();
      });
    // fbq;
    if (window.fbq) {
      let event_id = Math.round(Math.random() * 10000000000);
      window.fbq(
        "track",
        "InitiateCheckout",
        {
          content_category: orderDetail.vehicleModel,
          content_ids: [orderDetail.vehicleModel],
          currency: "JPY",
          value: orderDetail.amount,
          num_items: 1,
          contents: [
            {
              id: orderDetail.vehicleModel,
              title: orderDetail.vehicleModel,
              quantity: 1,
            },
          ],
        },
        { eventID: event_id }
      );
      let customdata = {
        content_category: orderDetail.vehicleModel,
        content_ids: [orderDetail.vehicleModel],
        currency: "JPY",
        value: orderDetail.amount,
        num_items: 1,
        contents: [
          {
            id: orderDetail.vehicleModel,
            title: orderDetail.vehicleModel,
            quantity: 1,
          },
        ],
      };
      let userdata = {
        em: orderDetail.email,
        ph: orderDetail.phone,
        fn: orderDetail.firstName,
        ln: orderDetail.lastName,
        ct: "JP",
        st: orderDetail.state,
        zp: orderDetail.zipCode,
        country: orderDetail.country,
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      FacebookAPI.Facebook(
        event_id,
        "InitiateCheckout",
        customdata,
        userdata
      ).then((res) => {
        console.log(res);
      });
    }
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  render() {
    const {
      orderDetail,
      PlanList,
      clientSecret,
      stripePromise,
      userDetails,
      url,
      amount,
    } = this.state;

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret,
      appearance,
      locale: "ja",
    };

    return (
      <React.Fragment>
        <Reload {...this.props} reloadingShow={this.state.reloadingShow} />
        <div className="order-page">
          <HeadBar {...this.props} />
          <div className="order-box">
            <h1>注文情報の確認</h1>
            <div className="table">
              <div className="head">ブランドモデル</div>
              <div className="table-li">
                <div className="title">ブランドモデル</div>
                <div className="content">{orderDetail.vehicleModel}</div>
              </div>
              <div className="upload-row">
                <div className="upload" onClick={() => this.Upload("model")}>
                  アップデイト
                </div>
              </div>
            </div>
            <div className="table">
              <div className="head">ご注文について</div>
              <div className="table-li">
                <div className="title">名前</div>
                <div className="content">{orderDetail.firstName}</div>
              </div>
              <div className="table-li">
                <div className="title">ラストネーム</div>
                <div className="content">{orderDetail.lastName}</div>
              </div>
              <div className="table-li">
                <div className="title">電子メールアドレス</div>
                <div className="content">{orderDetail.email}</div>
              </div>
              <div className="table-li">
                <div className="title">ドライバーのEメールアドレス</div>
                <div className="content">{orderDetail.driveEmail}</div>
              </div>
              <div className="table-li">
                <div className="title">電話番号</div>
                <div className="content">{orderDetail.phone}</div>
              </div>
              <div className="table-li">
                <div className="title">運転手の電話番号</div>
                <div className="content">{orderDetail.drivePhone}</div>
              </div>
              <div className="table-li">
                <div className="title">都道府県</div>
                <div className="content">{orderDetail.state}</div>
              </div>
              {/* <div className="table-li">
                <div className="title">City</div>
                <div className="content">{orderDetail.city}</div>
              </div> */}
              <div className="table-li">
                <div className="title">住所</div>
                <div className="content">{orderDetail.address}</div>
              </div>
              <div className="table-li">
                <div className="title"> 郵便番号</div>
                <div className="content">{orderDetail.zipCode}</div>
              </div>
              <div className="table-li">
                <div className="title">国または地域</div>
                <div className="content">{orderDetail.country}</div>
              </div>
              <div className="upload-row">
                <div className="upload" onClick={() => this.Upload("customer")}>
                  アップデイト
                </div>
              </div>
            </div>
            <div className="table">
              <div className="head">アイデンティティ情報</div>
              <div className="table-li">
                <div className="title">書類表面</div>
                <div className="content">
                  <img src={BASE_URL + orderDetail.documentFront} alt="" />
                </div>
              </div>
              {orderDetail.identityType === "PassPort" ? (
                ""
              ) : (
                <div className="table-li">
                  <div className="title">裏面</div>
                  <div className="content">
                    <img src={BASE_URL + orderDetail.documentBack} alt="" />
                  </div>
                </div>
              )}

              <div className="table-li">
                <div className="title">セルフィー</div>
                <div className="content video-box">
                  <video
                    ref={this.receiveVideoRef}
                    src={BASE_URL + orderDetail.userSelfPictureOrVideo || ""}
                    alt=""
                    playsInline={true}
                    autoPlay={true}
                  />
                </div>
              </div>
              <div className="upload-row">
                <div className="upload" onClick={() => this.Upload("card")}>
                  アップデイト
                </div>
              </div>
            </div>
            <div className="choose-box">
              <h2>プランの選択</h2>
              {PlanList.map((item, index) => {
                return (
                  <div
                    className="choose-li"
                    key={index}
                    onClick={() => this.changeChecked(index)}
                  >
                    <Radio checked={item.checked}></Radio> {item.title} JP￥
                    {amount}
                  </div>
                );
              })}
              {clientSecret !== "" ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm userDetails={userDetails} url={url} />
                </Elements>
              ) : (
                <div className="btn" onClick={this.clickToPay}>
                  クレジットカードでのお支払い
                </div>
              )}
              <div className="cancel-btn" onClick={this.routePage}>
                支払いのキャンセル
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Order;
