import React, { Component } from "react";
import "../style/plan.scss";
import HeadBar from "./common/HeadBar";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import Check from "../icon/Check";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Pagination, EffectCoverflow, EffectCards } from "swiper/modules";
import i5M60 from "../image/bmw-i5-m60.png";
import ixM60 from "../image/bmw-ix-m60.png";
import i4 from "../image/bmw-i4.png";
import bmw3 from "../image/bmw3.png";
import Hot from "../icon/Hot";
import NoCheck from "../icon/NoCheck";
import Previous from "../icon/Previous";
import Next from "../icon/Next";
import * as FacebookAPI from "../apis/FacebookAPI";
import * as SubmitPayAPI from "../apis/SubmitPayAPI";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "./common/Popup";
import Cookie from "react-cookies";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import Reload from "./common/Reload";

SwiperCore.use([EffectCoverflow, Pagination]);

class Plan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swiperList: [
        {
          title: "",
          price: "",
          description: "ベーシック",
          l1: "保険付き",
          l2: "自動運転",
          l4: "充電パイル",
          l5: "デュアルモーター全輪駆動",
          l6: "カスタムカラー",
          img: i5M60,
        },
        {
          title: "",
          price: "",
          description: "おすすめ",
          l1: "保険付き",
          l2: "自動運転",
          l4: "充電パイル",
          l5: "デュアルモーター全輪駆動",
          l6: "カスタムカラー",
          img: ixM60,
        },
        {
          title: "",
          price: "",
          description: "ほとんどの選択肢",
          l1: "保険付き",
          l2: "自動運転",
          l4: "充電パイル",
          l5: "デュアルモーター全輪駆動",
          l6: "カスタムカラー",
          img: i4,
        },
      ],
      initialSlide: 1,
      test_drive_package: 1,
      reloadingShow: false,
      payment_intent_id: null,
      clientSecret: "",
      stripePromise: "",
      userDetails: {},
      count: 3,
      url: "",
    };
    this.getCurrentIndex = this.getCurrentIndex.bind(this);
    this.clickToPay = this.clickToPay.bind(this);
    this.toggleReload = this.toggleReload.bind(this);
  }

  swiperRef = React.createRef();

  componentDidMount() {
    let { swiperList, test_drive_package } = this.state;
    swiperList.map((item, index) => {
      if (index === 0) {
        item.apply = Math.floor(Math.random() * (100 - 500 + 1)) + 500;
      } else if (index === 1) {
        item.apply = Math.floor(Math.random() * (1000 - 2000 + 1)) + 2000;
      } else {
        item.apply = Math.floor(Math.random() * (3000 - 4000 + 1)) + 4000;
      }
    });

    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    this.setState({
      swiperList,
      payment_intent_id: orderDetail.payment_intent_id,
    });
    if (orderDetail.test_drive_package) {
      let slideIndex = orderDetail.test_drive_package;
      this.setState(
        {
          initialSlide: parseInt(slideIndex),
          test_drive_package: parseInt(slideIndex),
        },
        () => {
          // 确保在更新state后再调用slideTo方法
          if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.swiperRef.current.swiper.slideTo(slideIndex);
          }
        }
      );
    }
    if (Cookie.load("prohibitd_purchase")) {
      console.log("禁止购买------");
      this.setState({ count: 0 });
    }
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.initialSlide !== this.state.initialSlide) {
      if (this.swiperRef.current && this.swiperRef.current.swiper) {
        this.swiperRef.current.swiper.slideTo(this.state.initialSlide);
      }
    }
  }

  clickToPay() {
    let { payment_intent_id, count, test_drive_package } = this.state;
    let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
    orderDetail.test_drive_package = test_drive_package;
    localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
    console.log(count);
    if (count === 0) {
      window.dispatchEvent(
        new CustomEvent("showAlert", {
          detail: {
            text: "本日の購入時間が到着しました!",
          },
        })
      );
      // 24小时内只允许点三次
      let time = new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
      let value = "Prohibitd purchase";
      Cookie.save("prohibitd_purchase", value, { expires: time, path: "/" });
      return;
    }
    count--;
    console.log(count);
    this.setState({ count });
    let ad = "";
    if (localStorage.getItem("ad")) {
      ad = JSON.parse(localStorage.getItem("ad")) || "";
      console.log(ad);
    }
    orderDetail.ad = ad;
    orderDetail.success_url = "/Success";
    orderDetail.cancel_url = "/Fail";
    orderDetail.payment_intent_id = payment_intent_id;
    orderDetail.type = 10;
    orderDetail.curcrency = 2;
    this.toggleReload();
    SubmitPayAPI.submit(orderDetail).then((res) => {
      console.log(res);
      this.toggleReload();
      var data = res.data.data.data;
      console.log(data);
      if (data !== null) {
        let stripePromise = loadStripe(data.api_publishable_key);
        // 自动滑到底部
        setTimeout(() => {
          window.scrollTo(0, document.documentElement.scrollHeight);
        }, 2000);
        let userDetails = {
          name: orderDetail.firstName,
          email: orderDetail.email,
          address: {
            // city: orderDetail.city,
            state: orderDetail.state,
            country: orderDetail.country,
            postal_code: orderDetail.zipCode,
            line1: orderDetail.address,
          },
        };
        this.setState({
          stripePromise,
          payment_intent_id: data.payment_intent_id,
          clientSecret: data.clientSecret,
          userDetails,
          url: `${BASE_URL}/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
          // url: `http://localhost:3000/success?payment_intent_id=${data.payment_intent_id}&status=succeeded&c_site_url=${data.c_site_url}&email=${orderDetail.email}&phone=${orderDetail.phone}&ad=${ad}`,
        });

        orderDetail.payment_intent_id = data.payment_intent_id;
        localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
      } else {
        this.clickToPay();
      }
    });
  }

  getCurrentIndex(e) {
    this.setState(
      {
        initialSlide: e.activeIndex,
        test_drive_package: e.activeIndex,
      },
      () => {
        let orderDetail = JSON.parse(localStorage.getItem("orderDetail"));
        orderDetail.test_drive_package = e.activeIndex;
        localStorage.setItem("orderDetail", JSON.stringify(orderDetail));
      }
    );
    // fbq;
    let { swiperList } = this.state;
    let event_id = Math.round(Math.random() * 10000000000);
    if (window.fbq) {
      window.fbq(
        "track",
        "CustomizeProduct",
        {
          content_name: swiperList[e.activeIndex].title,
          content_ids: [e.activeIndex],
          currency: "JPY",
          content_type: "plan",
          content_category: "drive_plan",
          contents: [
            {
              title: swiperList[e.activeIndex].title,
              id: e.activeIndex,
              quantity: "1",
            },
          ],
          value: swiperList[e.activeIndex].price,
        },
        { eventID: event_id }
      );
      let customdata = {
        content_name: swiperList[e.activeIndex].title,
        content_ids: [e.activeIndex],
        currency: "JPY",
        content_type: "plan",
        content_category: "drive_plan",
        contents: [
          {
            title: swiperList[e.activeIndex].title,
            id: e.activeIndex,
            quantity: "1",
          },
        ],
        value: swiperList[e.activeIndex].price,
      };
      let userdata = {
        em: "",
        ph: "",
        fn: "",
        ln: "",
        ct: "",
        st: "",
        zp: "",
        country: "",
        external_id: "",
        client_ip_address: "",
        client_user_agent: "",
        fbc: "",
        fbp: "",
        lead_id: "",
        anon_id: "",
        madid: "",
        page_id: "",
        page_scoped_user_id: "",
        ctwa_clid: "",
        ig_account_id: "",
        ig_sid: "",
      };
      FacebookAPI.Facebook(
        event_id,
        "CustomizeProduct",
        customdata,
        userdata
      ).then((res) => {
        console.log(res);
      });
    }
  }

  toggleReload() {
    this.setState({ reloadingShow: !this.state.reloadingShow });
  }

  render() {
    const {
      swiperList,
      initialSlide,
      clientSecret,
      stripePromise,
      userDetails,
      url,
    } = this.state;

    const appearance = {
      theme: "stripe",
    };
    const options = {
      clientSecret,
      appearance,
      locale: "ja",
    };

    return (
      <React.Fragment>
        <Reload {...this.props} reloadingShow={this.state.reloadingShow} />
        <div className="plan-page">
          <HeadBar {...this.props} />
          <div className="plan-box">
            {/* <h1>プランの選択</h1> */}
            <p>
              BMWの魅力を発見し、ドライビングの無限の歓びを感じてください!
              BMWの入念にデザインされた試乗パッケージで、BMWモデル・レンジのパフォーマンスとラグジュアリーを直接体験し、比類ないドライビングの旅に出かけましょう。
              今すぐBMW試乗パッケージを予約して、新しいドライビング体験を始めましょう!
              クリックして予約し、究極のドライビング・プレジャーを体験してください。
            </p>
            <div className="tip"></div>
            <div className="choose-row">
              <div className="choose-web">
                <Swiper
                  ref={this.swiperRef}
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={3}
                  pagination={{ clickable: true }}
                  initialSlide={initialSlide}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 1,
                    depth: 100,
                    modifier: 1,
                    //   slideShadows: true,
                  }}
                  onSlideChange={(e) => this.getCurrentIndex(e)}
                  modules={[EffectCoverflow, Pagination]}
                  className="mySwiper"
                >
                  {swiperList.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="img">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="hot">
                          <Hot size={30} /> <span>{item.apply} オプション</span>
                        </div>
                        <div className="little-tip">{item.description}</div>
                        <div className="title">
                          {item.title}
                          {/* {item.title} <span>¥{item.price}</span> */}
                        </div>

                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l1}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l2}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l3}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l4}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l5}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l6}</span>
                        </li>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            <div className="mobile-choose-row">
              <div className="choose-mobile">
                <Swiper
                  ref={this.swiperRef}
                  effect={"cards"}
                  grabCursor={true}
                  initialSlide={initialSlide}
                  modules={[EffectCards, Pagination]}
                  className="mySwiper"
                  pagination={{ clickable: true }}
                  onSlideChange={(e) => this.getCurrentIndex(e)}
                >
                  {swiperList.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="img">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="hot">
                          <Hot size={30} /> <span>{item.apply} オプション</span>
                        </div>
                        <div className="little-tip">{item.description}</div>
                        <div className="title">
                          {item.title}
                          {/* {item.title} <span>¥{item.price}</span>{" "} */}
                        </div>

                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l1}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l2}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l4}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l5}</span>
                        </li>
                        <li>
                          <Check size={20} color="#44BB00" />
                          <span>{item.l6}</span>
                        </li>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
            {clientSecret !== "" ? (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm userDetails={userDetails} url={url} />
              </Elements>
            ) : (
              <div className="btn" onClick={this.clickToPay}>
                クレジットカードでのお支払い
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Plan;
