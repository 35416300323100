import React from "react";

const Lihua = ({
    viewBox = "0 0  697 24",
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1697 1024"
    p-id="10312"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1.616842 739.085474l31.258947-50.526316c57.317053 56.212211 97.818947 91.809684 121.45179 106.765474 86.150737 54.514526 124.874105 25.6 163.516632 58.556631 25.761684 21.989053 52.493474 57.128421 80.195368 105.418105l-52.466526 48.397474c-3.341474-28.672-20.911158-58.017684-52.709053-88.037053-47.696842-45.002105-94.396632-21.611789-174.969263-72.784842-53.733053-34.142316-92.483368-70.063158-116.277895-107.789473z"
      fill="#FFBABA"
      p-id="10313"
    ></path>
    <path
      d="M673.684211 685.460211l18.782315-11.776c7.221895 33.738105 13.069474 56.023579 17.569685 66.883368 16.330105 39.558737 33.441684 37.456842 39.235368 58.394947 3.826526 13.931789 5.578105 32.983579 5.200842 57.128421l-24.872421 6.224842c4.230737-11.398737 4.230737-26.246737 0-44.517052-6.332632-27.405474-24.872421-29.184-40.124632-66.236632-10.159158-24.737684-15.413895-46.753684-15.791157-66.101894z"
      fill="#FFD600"
      p-id="10314"
    ></path>
    <path
      d="M1680.195368 596.992l-17.623579-16.653474c-16.410947 29.696-28.429474 48.990316-36.082526 57.882948-27.863579 32.498526-46.08 25.492211-57.667368 43.52-7.760842 11.991579-14.443789 29.453474-20.129684 52.331789l25.680842 13.204211c-1.751579-11.991579 1.994105-25.977263 11.210105-41.984 13.850947-23.983158 34.627368-20.183579 60.658526-50.661053 17.381053-20.291368 28.698947-39.504842 33.953684-57.667368zM245.463579 257.643789l22.366316-35.247157c76.907789 124.604632 122.610526 197.793684 137.162105 219.513263 52.924632 79.198316 23.120842 159.258947 46.484211 202.536421 15.575579 28.833684 37.025684 69.793684 64.350315 122.88l-40.097684 12.314947a1031.114105 1031.114105 0 0 0-52.224-126.248421c-28.645053-57.344 1.616842-128.215579-47.912421-202.482526a32714.401684 32714.401684 0 0 0-130.155789-193.266527z"
      fill="#A0E3FF"
      p-id="10315"
    ></path>
    <path
      d="M970.105263 27.109053L915.833263 0c-4.634947 59.203368-10.832842 97.387789-18.647579 114.553263-40.798316 89.815579-86.474105 115.738947-96.525473 135.168-47.211789 91.082105 41.606737 158.800842 24.926315 206.95579A4869.497263 4869.497263 0 0 0 781.473684 592.842105h52.385684a4566.986105 4566.986105 0 0 0 41.930106-136.165052c18.297263-63.137684-66.533053-121.586526-22.474106-206.95579 19.671579-38.076632 63.811368-55.161263 91.998316-108.705684 9.296842-17.677474 17.569684-55.646316 24.791579-113.906526z"
      fill="#D8A0FF"
      p-id="10316"
    ></path>
    <path
      d="M1127.558737 408.602947c5.712842 124.281263-34.465684 151.174737-24.441263 199.733895 6.682947 32.363789 16.249263 81.111579 28.672 146.189474h-35.84a1571.301053 1571.301053 0 0 0-16.545685-124.604632c-10.994526-63.649684 42.981053-135.221895 16.518737-221.318737 1.024 0 31.178105-9.889684 31.636211 0z"
      fill="#58FF99"
      p-id="10317"
    ></path>
    <path
      d="M1431.471158 134.736842H1482.105263c-58.745263 104.286316-81.839158 168.96-69.254737 194.101895 45.864421 91.540211-40.421053 159.582316-24.252631 207.979789 10.832842 32.282947 25.114947 77.877895 42.873263 136.865685h-69.793684c4.230737-34.573474 0.404211-73.027368-11.425685-115.334737-17.785263-63.461053 54.218105-143.710316 11.425685-229.510737-28.537263-57.209263-5.281684-121.909895 69.793684-194.101895z"
      fill="#FFD600"
      p-id="10318"
    ></path>
    <path
      d="M970.105263 700.631579m-26.947368 0a26.947368 26.947368 0 1 0 53.894737 0 26.947368 26.947368 0 1 0-53.894737 0Z"
      fill="#A0E3FF"
      p-id="10319"
    ></path>
    <path
      d="M538.947368 323.368421m-26.947368 0a26.947368 26.947368 0 1 0 53.894737 0 26.947368 26.947368 0 1 0-53.894737 0Z"
      fill="#FFBABA"
      p-id="10320"
    ></path>
    <path
      d="M1239.578947 269.473684m-26.947368 0a26.947368 26.947368 0 1 0 53.894737 0 26.947368 26.947368 0 1 0-53.894737 0Z"
      fill="#FFBABA"
      p-id="10321"
    ></path>
    <path
      d="M269.473684 673.684211m-26.947368 0a26.947368 26.947368 0 1 0 53.894737 0 26.947368 26.947368 0 1 0-53.894737 0Z"
      fill="#58FF99"
      p-id="10322"
    ></path>
    <path
      d="M1536 808.421053m-26.947368 0a26.947368 26.947368 0 1 0 53.894736 0 26.947368 26.947368 0 1 0-53.894736 0Z"
      fill="#D8A0FF"
      p-id="10323"
    ></path>
  </svg>
);

export default Lihua;